export default function loadSvgSprite(url, callback = () => {}) {
  const request = new XMLHttpRequest();

  request.open('GET', url, true);

  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      const div = document.createElement('div');
      const ready = () => {
        document.body.appendChild(div);
        callback();
      };

      div.setAttribute('style', 'height: 0; width: 0; position: absolute; visibility: hidden; display: none');
      div.innerHTML = request.responseText;

      if (document.readyState === 'complete') {
        ready();
      } else {
        document.addEventListener('DOMContentLoaded', () => ready(), false);
        window.addEventListener('load', () => ready(), false);
      }
    }
  };

  request.send();
}
